<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-layout wrap pa-3>
      <v-flex xs12>
        <span class="itemHeading1">Conflict Reports</span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center>
      <v-flex md12 >
          <ReportDetails  />
        </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import ReportDetails from "./reportDetails";
export default {
  components: {
    ReportDetails,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },

  beforeMount() {
    // this.getData();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
};
</script>