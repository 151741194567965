
<template>
  <div>
    <PageLoader :storage="appLoading" />
    <v-layout wrap>
      <v-flex xs12 md5>
        <v-layout
          @click="clickItem(item)"
          wrap
          v-for="(item, i) in caseDetails"
          style="cursor: pointer"
          :key="i"
          pt-4
        >
          <!-- <v-flex  v-if="item.status=='Pending'==playSound(
              'http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3'
          )">
          
            </v-flex> -->
          <v-flex
            xs3
            sm2
            md3
            lg2
            xl2
            :class="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? 'text-center'
                : 'text-left'
            "
            align-self-center
          >
            <v-avatar size="62px">
              <img alt="Avatar" :src="mediaURL + item.image" />
            </v-avatar>
          </v-flex>
          <v-flex xs8 sm7 md9 lg9 xl9>
            <v-layout wrap >
              <v-flex xs12 sm3 md6 lg4>
                <v-btn
                  x-small
                  depressed
                  class="py-2"
                  :outlined="item.status == 'Pending' ? true : false"
                  :ripple="false"
                  :color="item.status == 'Pending' ? '#deb710' : '#F5F5F5'"
                  style="letter-spacing: 0px"
                  ><span
                    style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 10px;
                    "
                    >RESPONSE NEEDED
                  </span></v-btn
                >
              </v-flex>
              <v-flex xs4 sm3 md6 lg4 text-left>
                <span
                  class="text-uppercase"
                  style="
                    font-family: sofiaProRegular;
                    color: #deb710;
                    font-size: 10px;
                  "
                >
                  {{ item.statuscopy }}</span
                >
              </v-flex>
              <v-flex xs12 sm3 md12 lg4 text-left>
                <span
                  class="text-uppercase"
                  style="
                    font-family: sofiaProRegular;
                    color: #deb710;
                    font-size: 10px;
                  "
                >
                  {{ item.scenarioId.name }}</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs8 sm7 md8 lg8 xl8>
                <span class="itemHeading">
                  {{ item.animalId.name }}
                </span>
              </v-flex>
              <v-flex xs4 sm4 md4 lg4 xl4 text-right>
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #000;
                    font-size: 10px;
                  "
                >
                  {{ timeSince(item.create_date) }}
                  <!-- <timeago :datetime="item.create_date" :auto-update="60"></timeago> -->
                </span>
              </v-flex>
              <v-flex xs12>
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                >
                  {{ item.address }}
                </span>
                <br />
                <span
                  v-if="item.userId.fullName"
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                >
                  Sighted by: {{ item.userId.fullName }}
                </span>
              </v-flex>
              <v-flex xs12 sm9 md12 lg12 xl2 pt-1>
                <v-btn
                  width="100%"
                  shaped
                  small
                  color="#000000"
                  @click="
                    $router.push('/Admin/cases/caseDetails/?id=' + item._id) &&
                      checkSound(item)
                  "
                >
                  <span v-if="item.scenarioId._id == '608f6c5930affc5c7ca00638'">
                    <span
                      v-if="item.status == 'Pending'"
                      style="color: #fff; font-size: 10px"
                      >Take Action
                    </span>
                    <span v-else style="color: #fff; font-size: 10px"
                      >View Details
                    </span>
                  </span>
                  
                    <span v-else style="color: #fff; font-size: 10px"
                      >View Details
                  </span>

                  <v-icon
                    style="
                      font-family: sofiaProRegular;
                      color: #ffffff;
                      font-size: 14px;
                    "
                    class="pl-3"
                    >mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 :key="i" pt-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 v-if="caseMsg" text-center>
        <span style="fonr-family: sofiaProBold; font-size: 18px"
          >No Data Found</span
        >
      </v-flex> -->
      <v-flex xs12 md7 px-md-1 px-lg-0>
        <div class="posSticky" id="map"></div>
      </v-flex>
    </v-layout>
  </div>
</template>
 <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
var audio;
var marker;
var infowindow;
export default {
  props: ["status"],
  data() {
    return {
      appLoading: false,
      map: "",
      caseDetails: [],
      infoItem: null,
      caseMsg: null,
      contentString: null,
    };
  },

  beforeMount() {
    // this.loadData();
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/conflicts/all/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.caseDetails = response.data.data;

            this.initMap();

            for (var i = 0; i < this.caseDetails.length; i++) {
              this.caseDetails[i].image = null;
              if (this.caseDetails[i].reportImages.length > 0)
                this.caseDetails[i].image = this.caseDetails[i].reportImages[0];
              else this.caseDetails[i].image = "noimage.jpg";
              if (!this.caseDetails[i].userId.fullName)
                this.caseDetails[i].userId.fullName = "Unknown";
              if (!this.caseDetails[i].address)
                this.caseDetails[i].address = "Unknown Address";
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    // loadData() {
    //   this.caseDetails = this.myData;
    //   for (var i = 0; i < this.caseDetails.length; i++) {
    //     this.caseDetails[i].image = null;
    //     if (this.caseDetails[i].reportImages.length > 0)
    //       this.caseDetails[i].image = this.caseDetails[i].reportImages[0];
    //     else this.caseDetails[i].image = "noimage.jpg";
    //     if (!this.caseDetails[i].userId.fullName)
    //       this.caseDetails[i].userId.fullName = "Unknown";
    //     if (!this.caseDetails[i].address)
    //       this.caseDetails[i].address = "Unknown Address";
    //     this.initMap();
    //   }
    // },
    clickItem(item) {
      // this.infoItem = item;
      for (var i = 0; i < this.caseDetails.length; i++) {
        if (this.caseDetails[i]._id == item._id) {
          infowindow.open(map, marker);
        }
      }
    },
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");
      var mapOptions = {
        center: new google.maps.LatLng(
          this.caseDetails[0].location[1],
          this.caseDetails[0].location[0]
        ),
        zoom: 12,

        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };
      var icon = {
        url: this.mediaURL + "footPrint.png", // url
        scaledSize: new google.maps.Size(25, 32), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0), // anchor
      };
      vm.map = new google.maps.Map(mapCanvas, mapOptions);
      for (var i = 0; i < this.caseDetails.length; i++) {
        var lat = this.caseDetails[i].location[1];
        var lon = this.caseDetails[i].location[0];
        var latlngset = new google.maps.LatLng(lat, lon);
        vm.contentString =
          `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 380px;">
                <div class="layout wrap justify-start pa-2">
                <div class="flex xs12 text-left py-1">
                        <span  class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: #EdEdEd;">
                            <span class="v-btn__content">
                                <span class="text-uppercase" style="color: #000;"> ` +
          this.caseDetails[i].animalId.name +
          `</span></span>
                                        </span>
                                            </div>
                                    <div class="flex xs12 text-left">
                          <span   style="font-family: sofiaProMedium;font-size:14px">` +
          this.caseDetails[i].address +
          `
                            </span>
                                  </div>
                     <div class="flex xs12 text-left pt-2">
                        <span class="smallLightHeading pl-2 "  >` +
          this.timeSince(this.caseDetails[i].create_date) +
          ` </span>
                    </div>
                
                 <div class="xs12 flex pt-2">
                 <div class="layout wrap">
                  <div class="flex xs2 text-left">
                 
                      <img
                        alt="Wildwatch"
                        style="border-radius: 50%;"
                        width="50px"
                        height="50px"
                        src=` +
          this.mediaURL +
          this.caseDetails[i].userId.photo +
          `
                      />
                      
                     
                  </div>
                  <div class="flex xs6 text-left"
                  <span class="smallLightHeading">Sighted By</span><br>
                     <span style="font-size:14px;font-family:sofiaProMedium">` +
          this.caseDetails[i].userId.fullName +
          `</span>
                  </div>
                 </div>
                </div>
                
                 <div class="xs12 flex">
                  <div class="layout wrap">
                 <div class="flex xs5 text-center py-1">
                        <span  class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--medium"
                        style="background-color: #FF6907 ;">
                            <span class="v-btn__content py-2 px-3" >
                            <i style="color:#FFF;font-size:18px;" class="fas fa-location-arrow"></i>
                                <span class="pl-1" style="font-family: sofiaProRegular;font-size:16px;color: #FFF;"> Direction</span>
                            </span>
                        </span>
                    </div> 
                      <div class="flex xs4 text-left  pl-3">
  
                        <span  class="itemKey text-capitalize  v-btn   v-btn--router v-btn-outlined theme--light v-size--medium"
                        style="background-color: #FFF ;border:.5px solid #EAEAEA;cursor:pointer">
                            <span class="v-btn__content py-2 px-3" >
                            <i style="color:#000;font-size:18px;" class="fas fa-phone-alt"></i>
                                <span class="pl-1" style="font-family: sofiaProRegular;font-size:16px;color: #000;"> Call</span>
                            </span>
                        </span>
                        
                    </div>
                     <div class="flex xs3 text-left ">
                          <span  class="itemKey text-capitalize  v-btn   v-btn--router v-btn-outlined theme--light v-size--medium"
                        style="background-color: #FFF ;border:.5px solid #EAEAEA;cursor:pointer">
                            <span class="v-btn__content py-2 px-3" >
                            <i style="color:#000;font-size:18px;" class="fas fa-share-alt"></i>
                                <span class="pl-1" style="font-family: sofiaProRegular;font-size:16px;color: #000;"> Share</span>
                            </span>
                        </span>
                    </div>
                 </div>
                </div>
                   
                </div>
            </div>
          `;

        infowindow = new google.maps.InfoWindow({
          content: vm.contentString,
        });
        marker = new google.maps.Marker({
          map: vm.map,
          position: latlngset,
          icon: icon,
        });
        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });

        // if(this.infoItem._id==this.caseDetails[i]._id)
        //   infowindow.open(map, marker);
        //   marker.addListener("click", () => {
        //     if(this.infoItem._id==this.caseDetails[i]._id)
        // {
        //   console.log("hiiiiiiiiii",this.infoItem._id,this.caseDetails[i]._id)
        //  infowindow.open(map, marker);
        // }
        // });
      }
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>
<style  >
.posSticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}
</style>

